@import url('https://fast.fonts.net/lt/1.css?apiType=css&c=4d686849-5ad7-4be5-8e55-b2365621fd2e&fontids=5549029,5548984,825448');

@font-face {
  font-family: 'Neue Haas Grotesk Display';
  src: url('5549029/6e329389-9c44-48b0-8291-f918840fe862.woff2') format('woff2'),
    url('5549029/dc6a6646-e0ac-4deb-b3c0-19e5dc30bf6a.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display';
  src: url('5548984/408d741e-b96a-486f-907a-770f7e11d6d3.woff2') format('woff2'),
    url('5548984/ec7b458e-6d24-4b3d-9c7f-43321a10ffe4.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Neue Haas Grotesk Text';
  font-style: normal;
  font-weight: 500;

  src: url('825448/83daa6d0-79e4-4489-b513-acebd8761116.woff2') format('woff2'),
    url('825448/19b27aaa-69bc-4951-ab70-a4ccf86edaf1.woff') format('woff');
}
